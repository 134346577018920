import React, { useEffect, useState } from "react";
import NavBar from "../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../components/customer/NavigationCustomer";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Footer from "../../../components/Footer/Footer";
import PageHero from "../../../components/Pagehero/PageHero";
import SquareComponentIt from "../../../components/InfoSquares/InfoSquaresIt/InfoSquaresIt";
import SquareComponent from "../../../components/InfoSquares/InfoSquaresSales/InfoSquares";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import KeyIcon from "@mui/icons-material/Key";
import HouseIcon from "@mui/icons-material/House";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import GasMeterIcon from "@mui/icons-material/GasMeter";
import { IoKeySharp, IoEyeSharp, IoEyeOffSharp } from "react-icons/io5";
import { MenuItem } from "@mui/material";
import "./BoilerMaintenanceApplicationCustomer.css";
import CenterTitle from "../../../components/CenterTitle/CenterTitle";
import "./../../../theme/styles.css";
import { getCookie } from "../../../cookieUtils";

interface User {
  id: string;
  companyUserId: string;
  surname: string;
  name: string;
  email: string;
  phone: string;
  streetname: string;
  housenumber: string;
  zipcode: string;
  city: string;
  eane: string;
  eang: string;
  password: string;
  roleId: number;
}

interface SolarPanelRequest {
  id: string;
  userId: string;
  brand: string;
  type: string;
  age: number;
  fuel: number;
  streetname: string;
  housenumber: string;
  zipcode: string;
  city: string;
  comment: string;
  status: string;
  datetimeCreated: Date;
}

const BoilerMaintenanceApplicationCustomer: React.FC = () => {
  // params users
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [streetname, setStreetname] = useState("");
  const [housenumber, setHousenumber] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [city, setCity] = useState("");

  const [isStreetnameTouched, setIsStreetnameTouched] = useState(false);
  const [isHousenumberTouched, setIsHousenumberTouched] = useState(false);
  const [isZipcodeTouched, setIsZipcodeTouched] = useState(false);
  const [isCityTouched, setIsCityTouched] = useState(false);

  const [brand, setBrand] = useState("");
  const [type, setType] = useState("");
  const [age, setAge] = useState<number>();
  const [fuel, setFuel] = useState(1);
  const [comment, setComment] = useState("");
  const [status, setStatus] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [roleId, setRoleId] = useState<number | null>(null);

  // Get stored userdata
  const storedUser = getCookie("user");
  const user = storedUser ? JSON.parse(storedUser) : null;
  const userId = user.id;

  // Handle data
  const handleBrandChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBrand(event.target.value);
  };
  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setType(event.target.value);
  };

  const handleAgeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value, 10);
    setAge(newValue);
  };

  // const handleFuelChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   const newValue = parseInt(event.target.value, 10);
  //   setFuel(newValue);
  // };

  const handleFuelChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newValue = parseInt(event.target.value as string, 10);
    setFuel(newValue);
  };

  const handleStreetnameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setStreetname(event.target.value);
    setIsStreetnameTouched(true);
  };

  const handleHousenumberChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setHousenumber(event.target.value);
    setIsHousenumberTouched(true);
  };

  const handleZipcodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setZipcode(event.target.value);
    setIsZipcodeTouched(true);
  };

  const handleCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCity(event.target.value);
    setIsCityTouched(true);
  };

  const handleStatus = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = parseInt(event.target.value, 10);
    setStatus(newValue);
  };

  const handleComment = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  const containerStyles: React.CSSProperties = {
    display: "flex",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
  };

  const dashboardContainerCouStyles: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
  }, []);
  // Get all the data of user and predefine it
  // Set initial state values for the input fields
  useEffect(() => {
    if (user && !isStreetnameTouched) {
      setStreetname(user.streetname);
    }
    if (user && !isHousenumberTouched) {
      setHousenumber(user.housenumber);
    }
    if (user && !isZipcodeTouched) {
      setZipcode(user.zipcode);
    }
    if (user && !isCityTouched) {
      setCity(user.city);
    }
  }, [
    user,
    isStreetnameTouched,
    isHousenumberTouched,
    isZipcodeTouched,
    isCityTouched,
  ]);

  // Handle form submission
  const handleFormSubmit = async (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();

    // Create a payload object with form data
    const payload = {
      userId, // Set the user ID accordingly
      brand,
      type,
      age,
      fuel,
      streetname,
      housenumber,
      zipcode,
      city,
      comments: comment,
      status: 1, // Set the status accordingly
    };

    try {
      // Make the API call to create the solar panel request
      const response = await axios.post(
        `${process.env.REACT_APP_DB_URL}/BoilerMaintenanceRequest`,
        payload,
      );
      navigate("/QuotesSuccessPage/");
    } catch (error) {
      console.error("Error creating solar panel request:", error);
      setErrorMessage(
        "De verplichte velden mogen niet leeg zijn of enkel 0 bevatten.",
      );
    }
  };

  return (
    <>
      {roleId === 2 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Offerteaanvraag Ketelonderhoud</PageHero>
          <div className="pageContainerBoiler" style={containerStyles}>
            <NavigationCustomer
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="parentContainer">
              <div
                className="contentContainersolar"
                style={contentContainerStyles}
              >
                <div className="userCardRequestsolarBoiler">
                  <div className="userCardRequestColumnssolarBoiler">
                    <div className="addressFieldsColumnBoiler">
                      {errorMessage ? (
                        <p className="errorMessage">{errorMessage}</p>
                      ) : null}

                    

                      {/* <div className="form-group">
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            label="Merk"
                            variant="standard"
                            type="text"
                            id="brand"
                            value={brand}
                            onChange={handleBrandChange}
                            required
                            fullWidth
                          />
                        </Box>
                      </div> */}
<div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Merk"
      variant="standard"
      type="text"
      id="brand"
      value={brand}
      onChange={handleBrandChange}
      required
      fullWidth
    />
  </Box>
  {brand === "" && (
    <span style={{ color: "red" }}>Merk is verplicht.</span>
  )}
</div>

                    

                      {/* <div className="form-group">
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            label="Type"
                            variant="standard"
                            type="text"
                            id="type"
                            value={type}
                            onChange={handleTypeChange}
                            required
                            fullWidth
                          />
                        </Box>
                      </div> */}

<div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Type"
      variant="standard"
      type="text"
      id="type"
      value={type}
      onChange={handleTypeChange}
      required
      fullWidth
    />
  </Box>
  {type === "" && (
    <span style={{ color: "red" }}>Type is verplicht.</span>
  )}
</div>



                      {/* <div className="form-group">
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            label="Leeftijd"
                            variant="standard"
                            type="number"
                            id="age"
                            value={age}
                            onChange={handleAgeChange}
                            required
                            fullWidth
                          />
                        </Box>
                      </div> */}

                      
<div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Leeftijd"
      variant="standard"
      type="number"
      id="age"
      value={age}
      onChange={handleAgeChange}
      required
      fullWidth
    />
  </Box>
  {age === undefined && (
    <span style={{ color: "red" }}>Leeftijd is verplicht.</span>
  )}
  {age !== undefined && age <= 0 && (
    <span style={{ color: "red" }}>Leeftijd moet een positief getal zijn.</span>
  )}
</div>


                      <div className="form-group">
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            id="fuel"
                            select
                            label="Brandstof *"
                            value={fuel}
                            onChange={handleFuelChange}
                            variant="standard"
                            fullWidth
                          >
                            <MenuItem value={1}>Mazout</MenuItem>
                            <MenuItem value={2}>Gas</MenuItem>
                          </TextField>
                        </Box>
                      </div>

                    
                      {/* <div className="form-group">
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            label="Straatnaam"
                            variant="standard"
                            type="text"
                            id="streetname"
                            value={streetname}
                            onChange={handleStreetnameChange}
                            required
                            fullWidth
                          />
                        </Box>
                      </div>

                      <div className="form-group">
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            label="Huisnummer"
                            variant="standard"
                            type="text"
                            id="housenumber"
                            value={housenumber}
                            onChange={handleHousenumberChange}
                            required
                            fullWidth
                          />
                        </Box>
                      </div> */}

<div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Straatnaam"
      variant="standard"
      type="text"
      id="streetname"
      value={streetname}
      onChange={handleStreetnameChange}
      required
      fullWidth
    />
  </Box>
  {streetname === "" && (
    <span style={{ color: "red" }}>Straatnaam is verplicht.</span>
  )}
</div>

<div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Huisnummer"
      variant="standard"
      type="text"
      id="housenumber"
      value={housenumber}
      onChange={handleHousenumberChange}
      required
      fullWidth
    />
  </Box>
  {housenumber === "" && (
    <span style={{ color: "red" }}>Huisnummer is verplicht.</span>
  )}
  {housenumber !== "" && !/^[0-9a-zA-Z]+$/.test(housenumber) && (
    <span style={{ color: "red" }}>
      Huisnummer mag alleen letters en cijfers bevatten.
    </span>
  )}
</div>
                     

                      {/* <div className="form-group">
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            label="Postcode"
                            variant="standard"
                            type="text"
                            id="zipcode"
                            value={zipcode}
                            onChange={handleZipcodeChange}
                            required
                            fullWidth
                          />
                        </Box>
                      </div>

                      <div className="form-group">
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            label="Stad/Gemeente"
                            variant="standard"
                            type="text"
                            id="city"
                            value={city}
                            onChange={handleCityChange}
                            required
                            fullWidth
                          />
                        </Box>
                      </div> */}

<div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Postcode"
      variant="standard"
      type="text"
      id="zipcode"
      value={zipcode}
      onChange={handleZipcodeChange}
      required
      fullWidth
    />
  </Box>
  {zipcode === "" && (
    <span style={{ color: "red" }}>Postcode is verplicht.</span>
  )}
  {zipcode !== "" && !/^[0-9]+$/.test(zipcode) && (
    <span style={{ color: "red" }}>Postcode mag alleen nummers bevatten.</span>
  )}
</div>

<div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Stad/Gemeente"
      variant="standard"
      type="text"
      id="city"
      value={city}
      onChange={handleCityChange}
      required
      fullWidth
    />
  </Box>
  {city === "" && (
    <span style={{ color: "red" }}>Stad/Gemeente is verplicht.</span>
  )}
  {city !== "" && !/^[A-Za-z\s]+$/.test(city) && (
    <span style={{ color: "red" }}>
      Stad/Gemeente mag alleen letters en spaties bevatten.
    </span>
  )}
</div>
                      
                    </div>
                  </div>

                  <div className="form-group">
                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                      <TextField
                        label="Opmerkingen"
                        variant="standard"
                        type="text"
                        rows={4}
                        multiline
                        id="comment"
                        value={comment}
                        onChange={handleComment}
                        fullWidth
                      />
                    </Box>
                  </div>
                  {streetname !== "" &&
  housenumber !== "" &&
  zipcode !== "" &&
  city !== "" &&
  brand !== "" &&
  type !== "" &&
  age !== undefined &&
  age > 0 ? (
  <button className="buttonstyle33" onClick={handleFormSubmit}>
    Aanvraag indienen
  </button>
) : (
  <span style={{ color: "red" }}>
    Vul alle velden correct in voordat u de aanvraag indient.
  </span>
)}

                  {/* <button className="buttonstyle33" onClick={handleFormSubmit}>
                    Aanvraag indienen
                  </button> */}
                </div>
              </div>
              <div className="textColumnsolar">
                <div
                  className="dashboardContainer"
                  style={dashboardContainerCouStyles}
                >
                  <div className="squareNavStyles">
                    <div style={{ display: "flex", alignItems: "left" }}>
                      <div>
                        <h3 className="squareNavTextStyles">Meer informatie</h3>
                        <p className="squareNavParagraphsStyles">
                          Bekijk meer informatie over onze oplossingen omtrend
                          ketelonderhoud
                        </p>
                        <button
                          className="buttonWithArrow"
                          onClick={() => navigate("/Quotes#ketelonderhoud")}
                        >
                          Klik hier
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/*     
        <SquareComponent />
        <SquareComponentIt /> */}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div> </div>
      )}
    </>
  );
};

export default BoilerMaintenanceApplicationCustomer;
