import React, { useState } from "react";
import "./Login.css";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { IoEyeSharp, IoEyeOffSharp } from "react-icons/io5";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/AccountCircle";
import KeyIcon from "@mui/icons-material/Key";
import { setCookie } from "../../../cookieUtils";
import logo from "./logo.png"; // Adjust the path to your logo

const Login = () => {
  const { handleSubmit, register } = useForm();
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isUsernameFocused, setUsernameFocused] = useState(false);
  const [isPasswordFocused, setPasswordFocused] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (data: any) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DB_URL}/login`,
        {
          email: data.email,
          password: data.password,
        }
      );

      const { roleId, user } = response.data;
      setCookie("user", JSON.stringify(user), 2);
      setCookie("roleId", JSON.stringify(roleId), 2);

      // Redirect based on role
      roleId === 1 ? navigate("/IndexAdministrator") : navigate("/IndexCustomer");
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMessage(error.response.data.error);
      } else {
        setErrorMessage("Er ging iets mis tijdens het inloggen.");
      }
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  return (
    <div className="login-page">
      <div className="login-background">
        <img src={logo} alt="Logo" className="login-logo" />
      </div>
      <div className="login-form">
        <form className="login-form" onSubmit={handleSubmit(handleLogin)}>
          <h2>Login</h2>
          <div className={`form-group ${isUsernameFocused ? "focused" : ""}`}>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <AccountCircle sx={{ color: "action.active", mr: 1, my: 0.5 }} />
              <TextField
                id="input-with-sx"
                label="E-mail adres"
                variant="standard"
                fullWidth
                {...register("email", { required: true })}
                onFocus={() => setUsernameFocused(true)}
                onBlur={() => setUsernameFocused(false)}
              />
            </Box>
          </div>
          <div className={`form-group ${isPasswordFocused ? "focused" : ""}`}>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <KeyIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <InputLabel htmlFor="password">Wachtwoord</InputLabel>
                <Input
                  fullWidth
                  type={isPasswordVisible ? "text" : "password"}
                  id="password"
                  {...register("password", { required: true })}
                  onFocus={() => setPasswordFocused(true)}
                  onBlur={() => setPasswordFocused(false)}
                  endAdornment={
                    <InputAdornment position="end">
                      {isPasswordVisible ? (
                        <IoEyeOffSharp
                          size={24}
                          onClick={togglePasswordVisibility}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <IoEyeSharp
                          size={24}
                          onClick={togglePasswordVisibility}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box>
          </div>
          <div className="link-container">
            <Link to="/Register" className="custom-link1">
              Nieuw account
            </Link>
            <Link to="/PasswordForgotten" className="custom-link2">
              Wachtwoord vergeten
            </Link>
          </div>
          <button className="login-button" type="submit">
            Login
          </button>
          {errorMessage && <p className="error-text">{errorMessage}</p>}
        </form>
      </div>
    </div>
  );
};

export default Login;





// import React, { useState } from "react";
// import "./Login.css";
// import axios from "axios";
// import { useForm } from "react-hook-form";
// import { Link, useNavigate } from "react-router-dom";
// import { IoKeySharp, IoEyeSharp, IoEyeOffSharp } from "react-icons/io5";
// import Box from "@mui/material/Box";
// import Input from "@mui/material/Input";
// import InputLabel from "@mui/material/InputLabel";
// import InputAdornment from "@mui/material/InputAdornment";
// import FormControl from "@mui/material/FormControl";
// import TextField from "@mui/material/TextField";
// import AccountCircle from "@mui/icons-material/AccountCircle";
// import KeyIcon from "@mui/icons-material/Key";
// import "./../../../theme/styles.css";
// import YouTube from "react-youtube";
// import { setCookie } from "../../../cookieUtils";

// const Login = () => {
//   const { handleSubmit, register, setError } = useForm();
//   const [isPasswordVisible, setPasswordVisible] = useState(false);
//   const [errorMessage, setErrorMessage] = useState("");
//   const [isUsernameFocused, setUsernameFocused] = useState(false);
//   const [isPasswordFocused, setPasswordFocused] = useState(false);
//   const navigate = useNavigate();

//   const handleLogin = async (data: any) => {
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_DB_URL}/login`,
//         {
//           email: data.email,
//           password: data.password,
//         },
//       );

//       console.log(response.data); // You can handle the response data here

//       // Login successful
//       const { roleId, user } = response.data;
//       setCookie("user", JSON.stringify(user), 2);
//       setCookie("roleId", JSON.stringify(roleId), 2);

//       // Check the user's role
//       if (roleId === 1) {
//         // Redirect to indexAdministrator page
//         navigateToIndexAdministrator();
//       } else {
//         // setCookie("user", JSON.stringify(user));
//         // setCookie("roleId", JSON.stringify(roleId));
//         // // Redirect to indexCustomer page
//         navigateToIndexCustomer();
//       }
//     } catch (error: any) {
//       if (error.response && error.response.data && error.response.data.error) {
//         setErrorMessage(error.response.data.error);
//       } else {
//         setErrorMessage("Er ging iets mis tijdens het inloggen.");
//       }
//     }
//   };

//   const navigateToIndexAdministrator = () => {
//     navigate("/IndexAdministrator");
//   };

//   const navigateToIndexCustomer = () => {
//     navigate("/IndexCustomer");
//   };

//   const togglePasswordVisibility = () => {
//     setPasswordVisible(!isPasswordVisible);
//   };

//   const handleUsernameFocus = () => {
//     setUsernameFocused(true);
//   };

//   const handleUsernameBlur = () => {
//     setUsernameFocused(false);
//   };

//   const handlePasswordFocus = () => {
//     setPasswordFocused(true);
//   };

//   const handlePasswordBlur = () => {
//     setPasswordFocused(false);
//   };

//   const opts = {
//     playerVars: {
//       autoplay: 1, // Autoplay the video
//       controls: 0, // Hide the player controls
//       modestbranding: 1, // Hide the YouTube logo
//       loop: 1, // Play the video on repeat
//       playlist: "wziCr2xFXX0", // Playlist to ensure the loop works
//     },
//   };

//   return (
//     <div className="login-page">
//       {/* <div className="login-image"> */}
//       <YouTube videoId="wziCr2xFXX0" className="login-video" opts={opts} />
//       {/* </div> */}

//       <div className="login-form">
//         <form className="login-form" onSubmit={handleSubmit(handleLogin)}>
//           <h2>Login</h2>
//           <div className={`form-group ${isUsernameFocused ? "focused" : ""}`}>
//             <Box sx={{ display: "flex", alignItems: "flex-end" }}>
//               <AccountCircle sx={{ color: "action.active", mr: 1, my: 0.5 }} />
//               <TextField
//                 id="input-with-sx"
//                 label="E-mail adres"
//                 variant="standard"
//                 fullWidth
//                 {...register("email", { required: true })}
//                 onFocus={handleUsernameFocus}
//                 onBlur={handleUsernameBlur}
//               />
//             </Box>
//           </div>
//           <div className={`form-group ${isPasswordFocused ? "focused" : ""}`}>
//             <Box sx={{ display: "flex", alignItems: "flex-end" }}>
//               <KeyIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
//               <FormControl variant="standard" sx={{ width: "100%" }}>
//                 <InputLabel htmlFor="password">Wachtwoord</InputLabel>
//                 <Input
//                   fullWidth
//                   type={isPasswordVisible ? "text" : "password"}
//                   id="password"
//                   {...register("password", { required: true })}
//                   onFocus={handlePasswordFocus}
//                   onBlur={handlePasswordBlur}
//                   endAdornment={
//                     <InputAdornment position="end">
//                       {isPasswordVisible ? (
//                         <IoEyeOffSharp
//                           size={24}
//                           onClick={togglePasswordVisibility}
//                           style={{ cursor: "pointer" }}
//                         />
//                       ) : (
//                         <IoEyeSharp
//                           size={24}
//                           onClick={togglePasswordVisibility}
//                           style={{ cursor: "pointer" }}
//                         />
//                       )}
//                     </InputAdornment>
//                   }
//                 />
//               </FormControl>
//             </Box>
//           </div>
//           <div className="link-container">
//             <Link to="/Register" className="custom-link1">
//               Nieuw account
//             </Link>
//             <Link to="/PasswordForgotten" className="custom-link2">
//               Wachtwoord vergeten
//             </Link>
//           </div>
//           <button className="login-button" type="submit">
//             Login
//           </button>
//           {errorMessage && <p className="error-text">{errorMessage}</p>}
//         </form>
//       </div>
//       {/* <div className="login-image"></div> */}
//     </div>
//   );
// };

// export default Login;
