import React, { useEffect, useState } from "react";
import NavBar from "../../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../../components/customer/NavigationCustomer";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Footer from "../../../../components/Footer/Footer";
import PageHero from "../../../../components/Pagehero/PageHero";
import "./SolarPanelsApplicationCustomer.css";
import SquareComponent from "../../../../components/InfoSquares/InfoSquaresSales/InfoSquares";
import SquareComponentIt from "../../../../components/InfoSquares/InfoSquaresIt/InfoSquaresIt";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import KeyIcon from "@mui/icons-material/Key";
import HouseIcon from "@mui/icons-material/House";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import GasMeterIcon from "@mui/icons-material/GasMeter";
import { IoKeySharp, IoEyeSharp, IoEyeOffSharp } from "react-icons/io5";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Tooltip,
} from "@mui/material";
import "./../../../../theme/styles.css";
import InfoIcon from "@mui/icons-material/Info";
import { getCookie } from "../../../../cookieUtils";
import { useForm } from "react-hook-form";
import { SolarPower } from "@mui/icons-material";

interface User {
  id: string;
  companyUserId: string;
  surname: string;
  name: string;
  email: string;
  phone: string;
  streetname: string;
  housenumber: string;
  zipcode: string;
  city: string;
  eane: string;
  eang: string;
  password: string;
  roleId: number;
}

interface SolarPanelRequest {
  id: string;
  userId: string;
  annualConsumption: number;
  roofingMaterial: number;
  connection: number;
  streetname: string;
  housenumber: string;
  zipcode: string;
  city: string;
  interestedInChargingStation: number;
  interestedInAirco: number;
  interestedInBattery: number;
  presentTechniquesSolar: number;
  presentTechniquesBattery: number;
  presentTechniquesPoints: number;
  presentTechniquesHeat: number;
  presentTechniquesEMS: number;
  presentTechniquesNone: number;
  presentTechniquesUnknown: number;
  quoteFor: number;
  powerSolar: string;
  powerConverter: string;
  brandConverter: string;
  batteryCapacity: string;
  comment: string;
  status: number;
  datetimeCreated: Date;
}

const SolarPanelsApplicationCustomer: React.FC = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  // params users
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [streetname, setStreetname] = useState("");
  const [housenumber, setHousenumber] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [city, setCity] = useState("");

  const [isStreetnameTouched, setIsStreetnameTouched] = useState(false);
  const [isHousenumberTouched, setIsHousenumberTouched] = useState(false);
  const [isZipcodeTouched, setIsZipcodeTouched] = useState(false);
  const [isCityTouched, setIsCityTouched] = useState(false);

  // params SolarPanelRequest
  // const [userId, setUserId] = useState("");
  const [annualConsumption, setAnnualConsumption] = useState<Number>();
  const [roofingMaterial, setRoofingMaterial] = useState(0);
  const [interestedInChargingStation, setinterestedInChargingStation] =
    useState(0);
  const [interestedInAirco, setinterestedInAirco] = useState(0);
  const [interestedInBattery, setinterestedInBattery] = useState(0);
  const [comment, setComment] = useState("");
  const [connection, setConnection] = useState(1);
  const [choice, setChoice] = useState(1);
  const [status, setStatus] = useState(0);

  const [presentTechniquesSolar, setPresentTechniquesSolar] = useState(0);
  const [presentTechniquesBattery, setPresentTechniquesBattery] = useState(0);
  const [presentTechniquesPoints, setPresentTechniquesPoints] = useState(0);
  const [presentTechniquesHeat, setPresentTechniquesHeat] = useState(0);
  const [presentTechniquesEMS, setPresentTechniquesEMS] = useState(0);
  const [presentTechniquesNone, setPresentTechniquesNone] = useState(0);
  const [presentTechniquesUnknown, setPresentTechniquesUnknown] = useState(1);
  const [powerSolar, setPowerSolar] = useState("");
  const [powerConverter, setPowerConverter] = useState("");
  const [brandConverter, setBrandConverter] = useState("");
  const [batteryCapacity, setBatteryCapacity] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [isRoofingMaterialTouched, setIsRoofingMaterialTouched] =
    useState(false);
  const [isConnectionTouched, setIsConnectionTouched] = useState(false);
  const [isChoiceTouched, setIsChoiceTouched] = useState(false);
  const [roleId, setRoleId] = useState<number | null>(null);

  const navigate = useNavigate();

  // Get stored userdata
  const storedUser = getCookie("user");
  const user = storedUser ? JSON.parse(storedUser) : null;
  const userId = user.id;

  // Handle data
  const handleStreetnameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setStreetname(event.target.value);
    setIsStreetnameTouched(true);
  };

  const handleHousenumberChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setHousenumber(event.target.value);
    setIsHousenumberTouched(true);
  };

  const handleZipcodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setZipcode(event.target.value);
    setIsZipcodeTouched(true);
  };

  const handleCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCity(event.target.value);
    setIsCityTouched(true);
  };

  const handleAnnualConsumption = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newValue = parseInt(event.target.value, 10);
    setAnnualConsumption(newValue);
  };

  const handleRoofingMaterial = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const newValue = parseInt(event.target.value as string, 10);
    setRoofingMaterial(newValue);
  };

  const handleConnection = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newValue = parseInt(event.target.value as string, 10);
    setConnection(newValue);
  };

  const handleChoice = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newValue = parseInt(event.target.value as string, 10);
    setChoice(newValue);
  };

  const handleStatus = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = parseInt(event.target.value, 10);
    setStatus(newValue);
  };

  const handleInterestedInChargingStation = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const newValue = parseInt(event.target.value as string, 10);
    setinterestedInChargingStation(newValue);
  };

  const handleInterestedInAirco = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const newValue = parseInt(event.target.value as string, 10);
    setinterestedInAirco(newValue);
  };

  const handleInterestedInBattery = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const newValue = parseInt(event.target.value as string, 10);
    setinterestedInBattery(newValue);
  };

  const handlePowerSolarChange = (event: any) => {
    setPowerSolar(event.target.value);
  };

  const handlePowerConverterChange = (event: any) => {
    setPowerConverter(event.target.value);
  };

  const handleBrandConverterChange = (event: any) => {
    setBrandConverter(event.target.value);
  };

  const handleBatteryCapacityChange = (event: any) => {
    setBatteryCapacity(event.target.value);
  };

  const handleCheckboxChange =
  (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked ? 1 : 0;

    if (field === "None" && checked) {
      // "None" is selected, so uncheck other fields and disable them
      setPresentTechniquesSolar(0);
      setPresentTechniquesBattery(0);
      setPresentTechniquesPoints(0);
      setPresentTechniquesHeat(0);
      setPresentTechniquesEMS(0);
      setPresentTechniquesNone(1);
      setPresentTechniquesUnknown(0);
    } else if (field === "Unknown" && checked) {
      // "Unknown" is selected, so uncheck other fields and disable them
      setPresentTechniquesSolar(0);
      setPresentTechniquesBattery(0);
      setPresentTechniquesPoints(0);
      setPresentTechniquesHeat(0);
      setPresentTechniquesEMS(0);
      setPresentTechniquesNone(0);
      setPresentTechniquesUnknown(1);
    } else if (field !== "None" && field !== "Unknown") {
      // If any other checkbox is selected, uncheck "None" and "Unknown"
      setPresentTechniquesNone(0);
      setPresentTechniquesUnknown(0);

      // Update the specific field
      switch (field) {
        case "Solar":
          setPresentTechniquesSolar(checked);
          break;
        case "Battery":
          setPresentTechniquesBattery(checked);
          break;
        case "Points":
          setPresentTechniquesPoints(checked);
          break;
        case "Heat":
          setPresentTechniquesHeat(checked);
          break;
        case "EMS":
          setPresentTechniquesEMS(checked);
          break;
        default:
          break;
      }
    }

    // Check if no other fields are selected and set "Unknown" accordingly
    if (
      !checked &&
      presentTechniquesSolar === 0 &&
      presentTechniquesBattery === 0 &&
      presentTechniquesPoints === 0 &&
      presentTechniquesHeat === 0 &&
      presentTechniquesEMS === 0 &&
      presentTechniquesNone === 0
    ) {
      setPresentTechniquesUnknown(1);
    }
  };



  const handleComment = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  const containerStyles: React.CSSProperties = {
    display: "flex",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    padding: "20px",
    transition: "all 0.3s",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
  };

  const dashboardContainerCouStyles: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
  }, []);

  useEffect(() => {
    if (user && !isStreetnameTouched) {
      setStreetname(user.streetname);
    }
    if (user && !isHousenumberTouched) {
      setHousenumber(user.housenumber);
    }
    if (user && !isZipcodeTouched) {
      setZipcode(user.zipcode);
    }
    if (user && !isCityTouched) {
      setCity(user.city);
    }
  }, [
    user,
    isStreetnameTouched,
    isHousenumberTouched,
    isZipcodeTouched,
    isCityTouched,
  ]);

  // Handle form submission
  const handleFormSubmit = async (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();

    // Check if roofingMaterial and connection have been touched
    // If not, set the default values for the first options
    const finalRoofingMaterial = isRoofingMaterialTouched ? roofingMaterial : 1;
    const finalConnection = isConnectionTouched ? connection : 1;

    // Create a payload object with form data
    const payload = {
      userId, // Set the user ID accordingly
      annualConsumption,
      roofingMaterial: finalRoofingMaterial,
      connection: finalConnection,
      streetname,
      housenumber,
      zipcode,
      city,
      interestedInChargingStation,
      interestedInAirco,
      interestedInBattery,
      presentTechniquesSolar,
      presentTechniquesBattery,
      presentTechniquesPoints,
      presentTechniquesHeat,
      presentTechniquesEMS,
      presentTechniquesNone,
      presentTechniquesUnknown,
      quoteFor: choice,
      powerSolar,
      powerConverter,
      brandConverter,
      batteryCapacity,
      comment,
      status: 1, // Set the status accordingly
    };

    try {
      // Make the API call to create the solar panel request
      const response = await axios.post(
        `${process.env.REACT_APP_DB_URL}/SolarPanelRequest`,
        payload,
      );

      navigate("/QuotesSuccessPage/");
    } catch (error) {
      console.error("Error creating solar panel request:", error);
      setErrorMessage(
        "De verplichte velden mogen niet leeg zijn of enkel 0 bevatten.",
      );
    }
  };

  const validateAnnualConsumption = (value: any) => {
    return value !== "" && parseFloat(value) !== 0;
  };

  return (
    <>
      {roleId === 2 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Offerteaanvraag zonnepanelen en thuisbatterijen </PageHero>
          <div className="pageContainerSP" style={containerStyles}>
            <NavigationCustomer
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="parentContainer">
              <div
                className="contentContainersolar"
                style={contentContainerStyles}
              >
                {/* <div className="contentContainersolar"> */}

                <div className="userCardRequestsolarSP">
                  <div className="userCardRequestColumnssolarSP">
                    <div className="addressFieldsColumnSP">
                      {errorMessage ? (
                        <p className="errorMessagesolar">{errorMessage}</p>
                      ) : null}

                      {/* Geschat jaarlijks verbruik */}
                      <div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <Tooltip
      title="deze info kan u terugvinden op eindafrekening energieleverancier/mijn fluvius portaal/…Bij verhuis: verschil tussen huidige meterstand en meterstand bij intrede omgerekend naar 12 maanden)"
      placement="top"
    >
      <InfoIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
    </Tooltip>
    <TextField
      fullWidth
      label="Geschat Jaarlijks verbruik"
      variant="standard"
      type="number"
      id="annualConsumption"
      value={annualConsumption}
      onChange={handleAnnualConsumption}
      required
    />
  </Box>
  {/* Error messages */}
  {annualConsumption === undefined ? (
    <span style={{ color: "red" }}>Geschat Jaarlijks verbruik is verplicht.</span>
  ) : (
    Number(annualConsumption) <= 0 && (
      <span style={{ color: "red" }}>
        Geschat Jaarlijks verbruik moet een positieve waarde zijn.
      </span>
    )
  )}
</div>


                      {/* <div className="form-group">
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <Tooltip
                            title="deze info kan u terugvinden op eindafrekening energieleverancier/mijn fluvius portaal/…Bij verhuis: verschil tussen huidige meterstand en meterstand bij intrede omgerekend naar 12 maanden)"
                            placement="top"
                          >
                            <InfoIcon
                              sx={{ color: "action.active", mr: 1, my: 0.5 }}
                            />
                          </Tooltip>
                          <TextField
                            fullWidth
                            label="Geschat Jaarlijks verbruik"
                            variant="standard"
                            type="number"
                            id="annualConsumption"
                            value={annualConsumption}
                            onChange={handleAnnualConsumption}
                            required
                          />
                        </Box>
                      </div> */}

                      <div className="form-group">
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            id="connection"
                            select
                            label="Aansluiting *"
                            value={connection}
                            onChange={(event) => {
                              setIsConnectionTouched(true);
                              handleConnection(event);
                            }}
                            variant="standard"
                            fullWidth
                            defaultValue="1"
                          >
                            <MenuItem value={parseInt("1")}>Monofase</MenuItem>
                            <MenuItem value={parseInt("2")}>3 fase</MenuItem>
                            <MenuItem value={parseInt("3")}>Onbekend</MenuItem>
                          </TextField>
                        </Box>
                      </div>

                      <div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Straatnaam"
      variant="standard"
      type="text"
      id="streetname"
      value={streetname}
      onChange={handleStreetnameChange}
      fullWidth
      required
    />
  </Box>
  {/* Error message */}
  {streetname.trim() === "" && (
    <span style={{ color: "red" }}>Straatnaam is verplicht.</span>
  )}
</div>


                      {/* Huisnummer */}
                      <div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Huisnummer"
      variant="standard"
      type="text"
      id="housenumber"
      value={housenumber}
      onChange={handleHousenumberChange}
      fullWidth
      required
    />
  </Box>
  {/* Error messages */}
  {housenumber.trim() === "" && (
    <span style={{ color: "red" }}>Huisnummer is verplicht.</span>
  )}
  {housenumber.trim() !== "" && !/^[0-9a-zA-Z]+$/.test(housenumber) && (
    <span style={{ color: "red" }}>
      Huisnummer mag alleen letters en cijfers bevatten.
    </span>
  )}
</div>


                      {/* Postcode */}
                      <div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Postcode"
      variant="standard"
      type="text"
      id="zipcode"
      value={zipcode}
      onChange={handleZipcodeChange}
      fullWidth
      required
    />
  </Box>
  {/* Error messages */}
  {zipcode.trim() === "" && (
    <span style={{ color: "red" }}>Postcode is verplicht.</span>
  )}
  {zipcode.trim() !== "" && !/^[0-9]+$/.test(zipcode) && (
    <span style={{ color: "red" }}>Postcode mag alleen nummers bevatten.</span>
  )}
</div>


                      {/* Stad/Gemeente */}
                      <div className="form-group">
  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <TextField
      label="Stad/Gemeente"
      variant="standard"
      type="text"
      id="city"
      value={city}
      onChange={handleCityChange}
      fullWidth
      required
    />
  </Box>
  {/* Error messages */}
  {city.trim() === "" && (
    <span style={{ color: "red" }}>Stad/Gemeente is verplicht.</span>
  )}
  {city.trim() !== "" && !/^[a-zA-Z\s]+$/.test(city) && (
    <span style={{ color: "red" }}>
      Stad/Gemeente mag alleen letters en spaties bevatten.
    </span>
  )}
</div>

                    </div>

                    <div className="addressFieldsColumn">
                      <div className="form-group">
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                          }}
                        >
                          <h4>
                            Welke van onderstaande technieken zijn reeds
                            aanwezig op het installatieadres?{" "}
                          </h4>
                          {/* Checkboxes for present techniques */}
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={presentTechniquesSolar === 1}
                                  onChange={handleCheckboxChange("Solar")}
                                  color="primary"
                                />
                              }
                              label="Zonnepanelen"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={presentTechniquesBattery === 1}
                                  onChange={handleCheckboxChange("Battery")}
                                  color="primary"
                                />
                              }
                              label="Thuisbatterij"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={presentTechniquesPoints === 1}
                                  onChange={handleCheckboxChange("Points")}
                                  color="primary"
                                />
                              }
                              label="Laadpunt(en)"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={presentTechniquesHeat === 1}
                                  onChange={handleCheckboxChange("Heat")}
                                  color="primary"
                                />
                              }
                              label="(hybride) Warmtepomp"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={presentTechniquesEMS === 1}
                                  onChange={handleCheckboxChange("EMS")}
                                  color="primary"
                                />
                              }
                              label="EMS"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={presentTechniquesNone === 1}
                                  onChange={handleCheckboxChange("None")}
                                  color="primary"
                                />
                              }
                              label="Geen van bovenstaande"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={presentTechniquesUnknown === 1}
                                  onChange={handleCheckboxChange("Unknown")}
                                  color="primary"
                                />
                              }
                              label="Onbekend"
                            />
                          </FormGroup>
                        </Box>
                      </div>

                      <div className="form-group">
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            id="choice"
                            select
                            label="Ik wens een offerte voor *"
                            value={choice}
                            onChange={(event) => {
                              setIsChoiceTouched(true);
                              handleChoice(event);
                            }}
                            variant="standard"
                            fullWidth
                            defaultValue={1}
                          >
                            <MenuItem value={parseInt("1")}>
                              Zonnepanelen
                            </MenuItem>
                            <MenuItem value={parseInt("2")}>
                              Zonnepanelen + thuisbatterij
                            </MenuItem>
                            <MenuItem value={parseInt("3")}>
                              Enkel thuisbatterij
                            </MenuItem>
                          </TextField>
                        </Box>
                      </div>

                      {/* Conditionally render fields if Solar checkbox is checked */}
                      {presentTechniquesSolar === 1 && (
                        <>
                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <TextField
                                id="roofingMaterial"
                                select
                                label="Type dakbedekking *"
                                value={roofingMaterial}
                                fullWidth
                                onChange={(event) => {
                                  setIsRoofingMaterialTouched(true);
                                  handleRoofingMaterial(event);
                                }}
                                variant="standard"
                              >
                                <MenuItem value={parseInt("1")}>Plat</MenuItem>
                                <MenuItem value={parseInt("2")}>
                                  Pannen
                                </MenuItem>
                                <MenuItem value={parseInt("3")}>Leien</MenuItem>
                                <MenuItem value={parseInt("4")}>
                                  Andere
                                </MenuItem>
                              </TextField>
                            </Box>
                          </div>

                          {/* Vermogen zonnepanelen */}
                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <Tooltip
                                title="Deze info kan u terugvinden op de oorspronkelijke offerte/factuur/subsidieaanvraag/eendraadsschema/mijn fluvius portaal/app omvormer/…"
                                placement="top"
                              >
                                <InfoIcon
                                  sx={{
                                    color: "action.active",
                                    mr: 1,
                                    my: 0.5,
                                  }}
                                />
                              </Tooltip>
                              <TextField
                                label="Vermogen huidige zonnepanelen (KwP)"
                                variant="standard"
                                type="text"
                                id="zonnepanelen"
                                value={powerSolar}
                                onChange={handlePowerSolarChange}
                                fullWidth
                                required
                              />
                            </Box>
                          </div>

                          {/* Vermogen omvormer */}
                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <Tooltip
                                title="Deze info kan u terugvinden op de oorspronkelijke offerte/factuur/subsidieaanvraag/eendraadsschema/mijn fluvius portaal/app omvormer/…"
                                placement="top"
                              >
                                <InfoIcon
                                  sx={{
                                    color: "action.active",
                                    mr: 1,
                                    my: 0.5,
                                  }}
                                />
                              </Tooltip>
                              <TextField
                                label="Vermogen omvormer (KvA)"
                                variant="standard"
                                type="text"
                                id="omvormer"
                                value={powerConverter}
                                onChange={handlePowerConverterChange}
                                fullWidth
                                required
                              />
                            </Box>
                          </div>

                          {/* Merk/Type omvormer */}
                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <Tooltip
                                title="Deze info kan u terugvinden op de oorspronkelijke offerte/factuur/subsidieaanvraag/eendraadsschema/mijn fluvius portaal/app omvormer/…"
                                placement="top"
                              >
                                <InfoIcon
                                  sx={{
                                    color: "action.active",
                                    mr: 1,
                                    my: 0.5,
                                  }}
                                />
                              </Tooltip>
                              <TextField
                                label="Merk/Type omvormer"
                                variant="standard"
                                type="text"
                                id="merktypeconverter"
                                value={brandConverter}
                                onChange={handleBrandConverterChange}
                                fullWidth
                                required
                              />
                            </Box>
                          </div>
                        </>
                      )}
                      {presentTechniquesBattery === 1 && (
                        <>
                          {/* Opslagcapaciteit huidige batterij */}
                          <div className="form-group">
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <Tooltip
                                title="Deze info kan u terugvinden op de oorspronkelijke offerte/factuur/subsidieaanvraag/eendraadsschema/mijn fluvius portaal/app omvormer/…"
                                placement="top"
                              >
                                <InfoIcon
                                  sx={{
                                    color: "action.active",
                                    mr: 1,
                                    my: 0.5,
                                  }}
                                />
                              </Tooltip>
                              <TextField
                                label="Opslagcapaciteit huidige batterij (KwH)"
                                variant="standard"
                                type="text"
                                id="opslagbatterij"
                                value={batteryCapacity}
                                onChange={handleBatteryCapacityChange}
                                fullWidth
                                required
                              />
                            </Box>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                      <TextField
                        label="Opmerkingen"
                        variant="standard"
                        type="text"
                        rows={4}
                        multiline
                        id="comment"
                        value={comment}
                        onChange={handleComment}
                        fullWidth
                      />
                    </Box>
                  </div>
                 
                  {
  (streetname && 
   housenumber && 
   zipcode && 
   city && 
   annualConsumption && 
   Number(annualConsumption) > 0 && 
   connection) ? (
    <button
      className="buttonstyle33"
      onClick={handleFormSubmit}
    >
      Aanvraag indienen
    </button>
  ) : (
    <div>Vul alle velden correct in alvorens een aanvraag in te dienen.</div>
  )
}

                  {/* <button className="buttonstyle33" onClick={handleFormSubmit}>
                    Aanvraag indienen
                  </button> */}
                </div>
              </div>
              <div className="textColumnsolar">
                <div
                  className="dashboardContainer"
                  style={dashboardContainerCouStyles}
                >
                  <div className="squareNavStyles">
                    <div style={{ display: "flex", alignItems: "left" }}>
                      <div>
                        <h3 className="squareNavTextStyles">Meer informatie</h3>
                        <p className="squareNavParagraphsStyles">
                          Bekijk meer informatie over onze oplossingen omtrend
                          zonnepanelen
                        </p>
                        <button
                          className="buttonWithArrow"
                          onClick={() => navigate("/Quotes#zonnepanelen")}
                        >
                          Klik hier
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div> </div>
      )}
    </>
  );
};

export default SolarPanelsApplicationCustomer;

{
  /* <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    id="interestedInChargingStation"
                    select
                    label="Bent u geïnteresseerd in een laadstation? *"
                    value={interestedInChargingStation}
                    onChange={handleInterestedInChargingStation}
                    variant="standard"
                    fullWidth
                  >
                    <MenuItem value={parseInt("1")}>Ja</MenuItem>
                    <MenuItem value={parseInt("2")}>Nee</MenuItem>
                  </TextField>
                </Box>
              </div> 


              <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    id="interestedInAirco"
                    select
                    label="Bent u geïnteresseerd in een airco/warmtepomp? *"
                    value={interestedInAirco}
                    onChange={handleInterestedInAirco}
                    variant="standard"
                    fullWidth
                  >
                    <MenuItem value={parseInt("1")}>Ja</MenuItem>
                    <MenuItem value={parseInt("2")}>Nee</MenuItem>
                  </TextField>
                </Box>
              </div> 



<div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    id="interestedInBattery"
                    select
                    label="  Bent u geïnteresseerd in een thuis batterij? *"
                    value={interestedInBattery}
                    onChange={handleInterestedInBattery}
                    variant="standard"
                    fullWidth
                  >
                    <MenuItem value={parseInt("1")}>Ja</MenuItem>
                    <MenuItem value={parseInt("2")}>Nee</MenuItem>
                  </TextField>
                </Box>
              </div> 

</div> */
}
