import React, { useEffect, useState } from "react";
import "./PasswordForgottenReset.css";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import KeyIcon from "@mui/icons-material/Key";
import { IoEyeSharp, IoEyeOffSharp } from "react-icons/io5";
import logo from "./logo.png"; // Adjust the path to your logo

interface PasswordRequest {
  id: string;
  userId: string;
  email: string;
  dateTimeCreated: Date;
}

interface User {
  id: string;
  companyUserId: string;
  surname: string;
  name: string;
  email: string;
  phone: string;
  streetname: string;
  housenumber: string;
  zipcode: string;
  city: string;
  password: string;
  roleId: number;
  dateTimeCreated: Date;
}

const PasswordForgottenReset: React.FC = () => {
  const { handleSubmit } = useForm();
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordRequest, setPasswordRequest] = useState<PasswordRequest | null>(null);
  const { passwordrequestId } = useParams<{ passwordrequestId: string }>();
  const [user, setUser] = useState<User | null>(null);
  const [isPasswordFocused, setPasswordFocused] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      // Fetch the password request
      const responsePas = await axios.get<PasswordRequest>(
        `${process.env.REACT_APP_DB_URL}/passwordrequest/${passwordrequestId}`
      );
      setPasswordRequest(responsePas.data);

      // Fetch the user associated with the password request
      const responseUser = await axios.get<User>(
        `${process.env.REACT_APP_DB_URL}/users/${responsePas.data.userId}`
      );
      setUser(responseUser.data);
    } catch (error) {
      console.error("Error retrieving data:", error);
      setErrorMessage("Fout bij het ophalen van gegevens. Probeer opnieuw.");
    }
  };

  const handlePasswordForgotten = async () => {
    try {
      if (!user) {
        setErrorMessage("Gebruiker niet gevonden.");
        return;
      }

      const updatedUser = {
        ...user,
        password: newPassword,
      };

      await axios.put(
        `${process.env.REACT_APP_DB_URL}/users/${user.id}`,
        updatedUser
      );
      navigate("/login");
    } catch (error) {
      console.error("Error updating user:", error);
      setErrorMessage("Fout bij het bijwerken van wachtwoord. Probeer opnieuw.");
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  const handleNewPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewPassword(event.target.value);
  };

  return (
    <div className="login-page">
      <div className="login-background">
        <img src={logo} alt="Logo" className="login-logo" />
      </div>
      <div className="login-form">
        <h2 className="title">Stel een nieuw wachtwoord in</h2>
        <p>
          Vul hier in het onderstaande veld een nieuw wachtwoord voor uw account
          in.
        </p>
        <div className={`form-group ${isPasswordFocused ? "focused" : ""}`}>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <KeyIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
            <FormControl variant="standard" sx={{ width: "100%" }}>
              <InputLabel htmlFor="password">Wachtwoord</InputLabel>
              <Input
                type={isPasswordVisible ? "text" : "password"}
                id="password"
                value={newPassword}
                onChange={handleNewPasswordChange}
                onFocus={() => setPasswordFocused(true)}
                onBlur={() => setPasswordFocused(false)}
                endAdornment={
                  <InputAdornment position="end">
                    {isPasswordVisible ? (
                      <IoEyeOffSharp
                        size={24}
                        onClick={togglePasswordVisibility}
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      <IoEyeSharp
                        size={24}
                        onClick={togglePasswordVisibility}
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>
        </div>
        <div className="link-container">
          <Link to="/Login" className="custom-link1">
            Inloggen
          </Link>
        </div>
        <button
          className="login-button"
          type="submit"
          onClick={handlePasswordForgotten}
        >
          Wachtwoord instellen
        </button>
        {errorMessage && <p className="error-text">{errorMessage}</p>}
      </div>
    </div>
  );
};

export default PasswordForgottenReset;





// import React, { useEffect, useState } from "react";
// import "./PasswordForgottenReset.css";
// import axios from "axios";
// import { useForm } from "react-hook-form";
// import { Link, useNavigate, useParams } from "react-router-dom";
// import Box from "@mui/material/Box";
// import Input from "@mui/material/Input";
// import InputLabel from "@mui/material/InputLabel";
// import InputAdornment from "@mui/material/InputAdornment";
// import FormControl from "@mui/material/FormControl";
// import KeyIcon from "@mui/icons-material/Key";
// import { IoEyeSharp, IoEyeOffSharp } from "react-icons/io5";
// import logo from "./logo.png"; // Adjust the path to your logo

// interface PasswordRequest {
//   id: string;
//   userId: string;
//   email: string;
//   dateTimeCreated: Date;
// }

// interface User {
//   id: string;
//   companyUserId: string;
//   surname: string;
//   name: string;
//   email: string;
//   phone: string;
//   streetname: string;
//   housenumber: string;
//   zipcode: string;
//   city: string;
//   password: string;
//   roleId: number;
//   dateTimeCreated: Date;
// }

// const PasswordForgottenReset: React.FC = () => {
//   const { handleSubmit } = useForm();
//   const [isPasswordVisible, setPasswordVisible] = useState(false);
//   const [errorMessage, setErrorMessage] = useState("");
//   const [passwordRequest, setPasswordRequest] = useState<PasswordRequest | null>(null);
//   const { passwordrequestId } = useParams<{ passwordrequestId: string }>();
//   const [user, setUser] = useState<User | null>(null);
//   const [isPasswordFocused, setPasswordFocused] = useState(false);
//   const [newPassword, setNewPassword] = useState("");
//   const navigate = useNavigate();

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const fetchData = async () => {
//     try {
//       const responsePas = await axios.get(
//         `${process.env.REACT_APP_DB_URL}/passwordrequest/${passwordrequestId}`
//       );
//       setPasswordRequest(responsePas.data);

//       const responseUser = await axios.get(
//         `${process.env.REACT_APP_DB_URL}/users/${responsePas.data.userId}`
//       );
//       setUser(responseUser.data);
//     } catch (error) {
//       console.error("Error retrieving data:", error);
//     }
//   };

//   const handlePasswordForgotten = async () => {
//     try {
//       const updatedUser = {
//         companyUserId: user?.companyUserId,
//         surname: user?.surname,
//         name: user?.name,
//         email: user?.email,
//         phone: user?.phone,
//         streetname: user?.streetname,
//         housenumber: user?.housenumber,
//         zipcode: user?.zipcode,
//         city: user?.city,
//         password: newPassword,
//         roleId: user?.roleId,
//       };
//       await axios.put(`${process.env.REACT_APP_DB_URL}/users/${user?.id}`, updatedUser);
//       navigate("/login");
//     } catch (error) {
//       console.error("Error updating user:", error);
//     }
//   };

//   const togglePasswordVisibility = () => {
//     setPasswordVisible(!isPasswordVisible);
//   };

//   const handleNewPasswordChange = (
//     event: React.ChangeEvent<HTMLInputElement>
//   ) => {
//     setNewPassword(event.target.value);
//   };

//   return (
//     <div className="login-page">
//       <div className="login-background">
//         <img src={logo} alt="Logo" className="login-logo" />
//       </div>
//       <div className="login-form">
//         <h2 className="title">Stel een nieuw wachtwoord in</h2>
//         <p>
//           Vul hier in het onderstaande veld een nieuw wachtwoord voor uw account
//           in.
//         </p>
//         <div className={`form-group ${isPasswordFocused ? "focused" : ""}`}>
//           <Box sx={{ display: "flex", alignItems: "flex-end" }}>
//             <KeyIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
//             <FormControl variant="standard" sx={{ width: "100%" }}>
//               <InputLabel htmlFor="password">Wachtwoord</InputLabel>
//               <Input
//                 type={isPasswordVisible ? "text" : "password"}
//                 id="password"
//                 value={newPassword}
//                 onChange={handleNewPasswordChange}
//                 onFocus={() => setPasswordFocused(true)}
//                 onBlur={() => setPasswordFocused(false)}
//                 endAdornment={
//                   <InputAdornment position="end">
//                     {isPasswordVisible ? (
//                       <IoEyeOffSharp
//                         size={24}
//                         onClick={togglePasswordVisibility}
//                         style={{ cursor: "pointer" }}
//                       />
//                     ) : (
//                       <IoEyeSharp
//                         size={24}
//                         onClick={togglePasswordVisibility}
//                         style={{ cursor: "pointer" }}
//                       />
//                     )}
//                   </InputAdornment>
//                 }
//               />
//             </FormControl>
//           </Box>
//         </div>
//         <div className="link-container">
//           <Link to="/Login" className="custom-link1">
//             Inloggen
//           </Link>
//           {/* <Link to="/Register" className="custom-link2">
//             Registreren
//           </Link> */}
//         </div>
//         <button
//           className="login-button"
//           type="submit"
//           onClick={handlePasswordForgotten}
//         >
//           Wachtwoord instellen
//         </button>
//         {errorMessage && <p className="error-text">{errorMessage}</p>}
//       </div>
//     </div>
//   );
// };

// export default PasswordForgottenReset;
